<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Category in City SEO

        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="6">
            <b-form-group
              label="Select City"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.city"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Select Category"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Courses"
                rules=""
              >
                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="coursesList"
                  :reduce="(val) => val"
                  @input="getContent(addCourseForm.category,addCourseForm.city)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="Title"
              label-for="blog-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-input v-model="addCourseForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="Description"
              label-for="blog-description"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-input v-model="addCourseForm.description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="Keywords"
              label-for="blog-content"
              class="mb-2"
            >

              <b-form-tags v-model="addCourseForm.keywords" />

            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <seo-categoy-in-city-list ref="table" />

    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BTable,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'
import SeoCategoyInCityList from './SeoCategoyInCityList.vue'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    // const store = useStore();
    const table = ref()

    const coursesList = ref([])
    const citiesList = ref([])

    store.dispatch('cities/getAllcitites').then(response => {
     //   console.log('response', response.data.data)
      citiesList.value = response.data.data
    })

    store.dispatch('categories/AllCategory').then(response => {
     //   console.log('response', response.data.data)
      coursesList.value = response.data.data
    })
    const form = ref()
    const addCourseForm = ref({
      category: '',
      keywords: '',
      city: '',
      title: '',
      description: '',
    })

    const getContent = (category, city) => {
      const categoryid = category.id

      store.dispatch('seo/GetCategoryInCity', { categoryid, city }).then(response => {
       
        
        // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
       
        addCourseForm.value.title = response.data.data.title
        addCourseForm.value.description = response.data.data.description
        addCourseForm.value.keywords = response.data.data.keywords.split(',')
      })
    }

    const addCourseFormvalidate = ref()

    const save = () => {
      
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('category_id', addCourseForm.value.category.id)
          formData.append('keywords', addCourseForm.value.keywords)
          formData.append('title', addCourseForm.value.title)
          formData.append('description', addCourseForm.value.description)
          formData.append('city_id', addCourseForm.value.city)

          store
            .dispatch('seo/addCategryInCity', formData)
            .then(response => {
              addCourseForm.value.category = ''
              addCourseForm.value.title = ''
              addCourseForm.value.description = ''
              table.value.refetchData()
              form.value.reset()
              Vue.swal({
                title: 'Category In City Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })

         //   console.log('alt_header_image', addCourseForm)
        }
      })
    }

    return {

      addCourseForm,
      table,
      addCourseFormvalidate,
      coursesList,
      citiesList,
      getContent,

      save,
      required,
      email,
      form,
    }
  },

  components: {
    BCard,
    BFormTags,
    BTable,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    SeoCategoyInCityList,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
